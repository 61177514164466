(
	function( insight, $ ) {
		insight = insight || {};
		$.extend( insight, {

			HeaderSticky: {

				init: function() {
					this.build();
					return this;
				},

				build: function() {
					if ( jQuery( '.header' ).hasClass( 'header-sticky' ) ) {
						var offset = jQuery( '.header' ).offset();
						var hheight = jQuery( '.header' ).height();
						jQuery( '.header.header-sticky' ).not( '.header-07' ).headroom(
							{
								offset: offset.top,
								onTop: function() {
									if ( jQuery( '.logo-image' ).attr( 'data-normal' ) != '' ) {
										jQuery( '.logo-image' ).attr( 'src', jQuery( '.logo-image' ).attr( 'data-normal' ) );
									}
									if ( jQuery( '.logo-image' ).attr( 'data-normal-retina' ) != '' ) {
										jQuery( '.logo-image' ).attr( 'srcset', jQuery( '.logo-image' ).attr( 'data-normal-retina' ) + ' 2x' );
									}
									if ( jQuery( '.header-overlay' ).length <= 0 ) {
										jQuery( 'html' ).css( {paddingTop: 0} );
									}
								},
								onNotTop: function() {
									if ( jQuery( '.logo-image' ).attr( 'data-sticky' ) != '' ) {
										jQuery( '.logo-image' ).attr( 'src', jQuery( '.logo-image' ).attr( 'data-sticky' ) );
									}
									if ( jQuery( '.logo-image' ).attr( 'data-sticky-retina' ) != '' ) {
										jQuery( '.logo-image' ).attr( 'srcset', jQuery( '.logo-image' ).attr( 'data-sticky-retina' ) + ' 2x' );
									}
									if ( jQuery( '.header-overlay' ).length <= 0 ) {
										jQuery( 'html' ).css( {paddingTop: hheight} );
									}
								},
							}
						);
					}
				}
			}

		} );
	}
).apply( this, [window.insight, jQuery] );
