(
	function( insight, $ ) {
		insight = insight || {};
		$.extend( insight, {

			PortfolioFilter: {

				init: function() {
					this.build();
					return this;
				},

				build: function() {
					setTimeout( function() {
						jQuery( window ).trigger( 'resize' );
					}, 1000 );

					jQuery( '.insight-portfolio-filter' ).imagesLoaded( function() {
						$( '.insight-portfolio-filter .insight-items' ).isotope( {
							itemSelector: '.insight-item',
							masonry: {
								isFitWidth: true
							}
						} );
					} );

					// Portfolio filter
					jQuery( '.insight-portfolio-filter' ).each( function() {
						var $this = jQuery( this );
						var data_id = $this.data( 'id' );
						var data_paging = $this.data( 'paging' );
						var data_perpage = $this.data( 'perpage' );
						if ( data_paging == 'yes' ) {
							var filterSelector = '#' + data_id;
							var itemSelector = '.insight-item';
							var $container = $( '#' + data_id + ' .insight-items' ).isotope( {
								itemSelector: itemSelector,
								masonry: {
									isFitWidth: true
								}
							} );
							var itemsPerPageDefault = data_perpage;
							var itemsPerPage = insight_define_per_page( itemsPerPageDefault );
							var currentNumberPages = 1;
							var currentPage = 1;
							var currentFilter = '*';
							var filterAtribute = 'class';
							var pageAtribute = 'data-page';
							var pagerClass = 'insight-pager';

							insight_set_pagination( itemSelector, currentFilter, filterAtribute, itemsPerPage, pageAtribute, pagerClass, currentNumberPages, currentPage, $container );
							insight_go_to_page( 1, itemSelector, currentFilter, filterAtribute, pageAtribute, currentPage, $container )
							// Filter
							$( filterSelector + ' .insight-filter a' ).click( function() {
								$( filterSelector + ' .insight-filter a' ).removeClass( 'active' );
								$( this ).addClass( 'active' );
								var filter = $( this ).attr( 'data-filter' );
								currentFilter = filter;
								insight_set_pagination( itemSelector, currentFilter, filterAtribute, itemsPerPage, pageAtribute, pagerClass, currentNumberPages, currentPage, $container );
								insight_go_to_page( 1, itemSelector, currentFilter, filterAtribute, pageAtribute, currentPage, $container )
							} );
							// Responsive
							$( window ).on( 'resize', function() {
								itemsPerPage = insight_define_per_page( itemsPerPageDefault );
								insight_set_pagination( itemSelector, currentFilter, filterAtribute, itemsPerPage, pageAtribute, pagerClass, currentNumberPages, currentPage, $container );
								insight_go_to_page( 1, itemSelector, currentFilter, filterAtribute, pageAtribute, currentPage, $container )
							} );
							// Paging
							$( filterSelector + ' .insight-pager a' ).on( 'click', function() {
								var scroll_to = jQuery( '#' + data_id ).offset().top;
								jQuery( 'body' ).animate( {scrollTop: scroll_to} );
								$( filterSelector + ' .insight-pager a' ).removeClass( 'active' );
								$( this ).addClass( 'active' );
							} );
						} else {
							var filterSelector = '#' + data_id;
							var itemSelector = '.insight-item';
							var $container = $( '#' + data_id + ' .insight-items' ).isotope( {
								itemSelector: itemSelector,
								masonry: {
									isFitWidth: true
								}
							} );
							var currentFilter = '*';
							var filterAtribute = 'class';
							setTimeout( function() {
								insight_change_filter( itemSelector, $container );
							}, 100 );

							// Filter
							$( filterSelector + ' .insight-filter a' ).click( function() {
								$( filterSelector + ' .insight-filter a' ).removeClass( 'active' );
								$( this ).addClass( 'active' );
								var filter = $( this ).attr( 'data-filter' );
								currentFilter = filter;

								var selector = itemSelector;
								selector += (
									currentFilter != '*'
								) ? '[' + filterAtribute + '~="' + currentFilter + '"]' : '';
								insight_change_filter( selector, $container );
							} );
						}
					} );

					function insight_change_filter( selector, $container ) {
						$container.isotope( {
							filter: selector
						} );
					}

					function insight_define_per_page( itemsPerPageDefault ) {
						var pages = itemsPerPageDefault;
						return pages;
					}

					function insight_go_to_page( n, itemSelector, currentFilter, filterAtribute, pageAtribute, currentPage, $container ) {
						currentPage = n;

						var selector = itemSelector;
						selector += (
							currentFilter != '*'
						) ? '[' + filterAtribute + '~="' + currentFilter + '"]' : '';
						selector += '[' + pageAtribute + '="' + currentPage + '"]';

						insight_change_filter( selector, $container );
					}

					function insight_set_pagination( itemSelector, currentFilter, filterAtribute, itemsPerPage, pageAtribute, pagerClass, currentNumberPages, currentPage, $container ) {
						var SettingsPagesOnItems = function() {
							var itemsLength = $container.children( itemSelector ).length;
							var pages = Math.ceil( itemsLength / itemsPerPage );
							var item = 1;
							var page = 1;
							var selector = itemSelector;
							selector += (
								currentFilter != '*'
							) ? '[' + filterAtribute + '~="' + currentFilter + '"]' : '';
							$container.children( selector ).each( function() {
								if ( item > itemsPerPage ) {
									page ++;
									item = 1;
								}
								jQuery( this ).attr( pageAtribute, page );
								item ++;
							} );
							currentNumberPages = page;
						}();
						var CreatePagers = function() {
							var $isotopePager = (
								jQuery( '.' + pagerClass ).length == 0
							) ? jQuery( '<div class="' + pagerClass + '"></div>' ) : jQuery( '.' + pagerClass );
							$isotopePager.html( '' );
							for ( var i = 0; i < currentNumberPages; i ++ ) {
								var $pager = jQuery( '<a href="javascript:void(0);" ' + pageAtribute + '="' + (
								                     i + 1
									) + '"></a>' );
								$pager.html( i + 1 );
								$pager.click( function() {
									var page = jQuery( this ).eq( 0 ).attr( pageAtribute );
									jQuery( this ).eq( 0 ).addClass( 'active' );
									insight_go_to_page( page, itemSelector, currentFilter, filterAtribute, pageAtribute, currentPage, $container );
								} );
								$pager.appendTo( $isotopePager );
							}
							$container.after( $isotopePager );
						}();
					}
				}
			}

		} );
	}
).apply( this, [window.insight, jQuery] );
