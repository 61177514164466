(
	function( insight, $ ) {
		insight = insight || {};
		$.extend( insight, {

			BlogMasonry: {

				init: function() {
					this.build();
					return this;
				},

				build: function() {
					$( '.blog-masonry .blog-masonry-item' ).each( function() {
						var data_bg = $( this ).find( '.inside' ).data( 'bg' );
						if ( data_bg != '' ) {
							$( this ).find( '.inside' ).css( 'background-image', 'url(' + data_bg + ')' );
						} else {
							$( this ).find( '.inside' ).addClass( 'no-bg' );
						}
					} );

					var $container = $( '.blog-masonry' ).isotope( {
						itemSelector: '.blog-masonry-item',
						percentPosition: true,
						masonry: {
							isFitWidth: true,
							columnWidth: '.item-sizer'
						}
					} );

					setTimeout( function() {
						$container.isotope( 'layout' );
					}, 100 );
				}
			}

		} );
	}
).apply( this, [window.insight, jQuery] );
